@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main-bg {
  background-color: #b8dcfa26;
  height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.title-bar {
  width: 100%;
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
  color: black;
}
.title-bar2 {
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  border: none;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #424242;
  color: white;
}
.title-bar2:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.excel-boxes-outer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.excel-boxes {
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}
.title-box {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: rgb(212, 68, 68);
}
.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.table-box {
  padding: 5rem 10rem;
}
.table-row {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.table-cells {
  font-size: 18px !important;
  font-weight: 700 !important;
}
@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .flex {
    flex-direction: column;
  }
  .title-bar {
    width: 100%;
    font-weight: 700;
    font-size: 2rem;
  }
  .title-bar2 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .excel-boxes-outer {
    flex-direction: column;
    gap: 1rem;
  }
  .excel-boxes {
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .title-box {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .table-box {
    padding: 0.5rem 1rem;
  }
  .table-row {
    font-size: 14px !important;
    font-weight: 700 !important;
    overflow: hidden !important;
    overflow-x: auto !important;
  }
  .table-cells {
    font-size: 14px !important;
    font-weight: 700 !important;
    white-space: nowrap !important;
    padding: 10px 15px !important;
  }
}
